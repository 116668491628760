<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <text-field
                    v-model="currentAccount.firstName"
                    data-field="firstName"
                    name="fname"
                    :label="$t('general.firstname')"
                    outlined
                    :dense="dense"
                    persistent-hint
                    required
                    :rules="nameRules"
                />
            </v-col>

            <v-col cols="12" md="6">
                <text-field
                    id="lname"
                    v-model="currentAccount.name"
                    data-field="name"
                    name="lname"
                    :label="$t('general.lastname')"
                    outlined
                    :dense="dense"
                    persistent-hint
                    required
                    :rules="nameRules"
                />
            </v-col>

            <v-col cols="12" md="6">
                <text-field
                    ref="emailField"
                    v-model="currentAccount.email"
                    data-field="email"
                    :label="$t('general.email')"
                    outlined
                    :dense="dense"
                    persistent-hint
                    required
                    :rules="emailRules"
                    type="email"
                />
            </v-col>

            <v-col cols="12" md="6">
                <text-field
                    v-model="currentAccount.phone"
                    type="tel"
                    name="phone"
                    data-field="phone"
                    :label="$t('general.phone')"
                    outlined
                    :dense="dense"
                    persistent-hint
                />
            </v-col>

            <v-col cols="12" md="6">
                <text-field
                    id="password"
                    v-model="currentAccount.password"
                    data-field="password"
                    :label="$t('general.password')"
                    autocomplete="new-password"
                    name="password"
                    outlined
                    :dense="dense"
                    persistent-hint
                    required
                    :rules="passwordRules"
                    type="password"
                />
            </v-col>

            <v-col cols="12" md="6">
                <text-field
                    v-model="currentAccount.passwordRepeat"
                    data-field="repeatPassword"
                    :label="$t('changePassword.confirm_password')"
                    autocomplete="new-password"
                    outlined
                    :dense="dense"
                    persistent-hint
                    required
                    :rules="passwordRules"
                    type="password"
                />
            </v-col>

            <v-col cols="12">
                <v-checkbox
                    v-model="agreedToTermsAndConditions"
                    dexxter-form-field="agreeToTermsAndPrivacyStatement"
                    required
                    class="ml-2"
                >
                    <template #label
                        ><p class="policy-and-terms">
                            {{ $t('registration.termsAndPrivacy.start') }}
                            <a href="https://dexxter.be/privacy-policy/" target="_blank" @click.stop>{{
                                $t('registration.termsAndPrivacy.privacyPolicy')
                            }}</a>
                            {{ $t('registration.termsAndPrivacy.middle') }}
                            <a href="https://dexxter.be/algemenevoorwaarden/" target="_blank" @click.stop>{{
                                $t('registration.termsAndPrivacy.terms')
                            }}</a>
                        </p>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
export default {
    mixins: [formRulesMixin],
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        hasCheckedTermsAndConditions: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            languages: [
                { label: 'Nederlands', value: 'nl' },
                { label: 'Français', value: 'fr' },
                { label: 'English', value: 'en' }
            ]
        };
    },
    computed: {
        ...mapFields('bottomSheets', ['currentAccount']),
        agreedToTermsAndConditions: {
            get() {
                return this.hasCheckedTermsAndConditions;
            },
            set(newHasCheckedTermsAndConditions) {
                this.$emit('update:hasCheckedTermsAndConditions', newHasCheckedTermsAndConditions);
            }
        }
    },
    mounted() {
        this.currentAccount.language = 'nl';
        // if (!this.currentAccount.language) this.currentAccount.language = this.$i18n.locale;
    }
};
</script>

<style scoped lang="scss">
.policy-and-terms {
    margin-bottom: 0;

    a {
        text-decoration: none;
        font-weight: bold;
    }
}
</style>
