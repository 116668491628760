
import AccountInfo from '@/components/AddAccountForms/AccountInfo.vue';
import Loader from '@/components/Loader.vue';
import { apiErrorAndDisplay, displayError } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { MapStateToComputed } from '@/helpers/types';
import { chatMixin } from '@/mixins/chat';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { analyticsIdentify, analyticsTrack } from '@/services/analytics';
import { BottomSheetsState } from '@/store/types/bottomSheets';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { resetLoggingState } from '../helpers/auth';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';
import { checkRedirectRegistrations } from '../services/auth';

export default defineComponent({
    components: { AccountInfo, Loader, OnboardingLayout },
    mixins: [formRulesMixin, chatMixin],
    data() {
        return {
            valid: false,
            agreedToPolicyAndTerms: false,
            registrationStep: 1,
            loading: false,
            loadingPage: true,
            mustRedirect: false
        };
    },

    created() {
        checkRedirectRegistrations()
            .then((redirect) => {
                if (redirect) {
                    this.mustRedirect = true;
                    window.location.href = 'https://go.dexxter.be/sign-up';
                }
            })
            .finally(() => {
                this.loadingPage = false;
            });
    },

    computed: {
        ...mapFields(['isLoggedin']),
        ...(mapFields('bottomSheets', ['currentAccount']) as MapStateToComputed<{
            currentAccount: BottomSheetsState['currentAccount'];
        }>),
        isValid(): boolean {
            return this.valid && this.passwordsMatch;
        },
        passwordsMatch(): boolean {
            return this.password === this.passwordRepeat;
        },
        cannotSubmitRegister(): boolean {
            return !(this.isValid && this.agreedToPolicyAndTerms);
        }
    },
    methods: {
        ...mapActions('auth', ['createUser', 'login', 'changeCognitoPassword']),
        goBack() {
            this.$router.back();
        },
        resetForm() {
            (this.$refs.form as any).reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;

                if (this.currentAccount.password !== this.currentAccount.passwordRepeat) {
                    displayError.call(
                        this,
                        this.$t('registration.passwordsDontMatch.title'),
                        this.$t('registration.passwordsDontMatch.content')
                    );
                    return;
                }

                if (!this.currentAccount.email) {
                    throw new Error('Mail not defined');
                }

                const emailInput = this.currentAccount.email.toLowerCase() as string;
                const passwordInput = this.currentAccount.password as string;

                const registerData = await this.createUser({
                    ...this.currentAccount,
                    email: emailInput
                });

                notify.call(this, {
                    title: this.$t('login.succesful_regisered'),
                    text: this.$t('login.succesful_regisered_text')
                });

                // Analytics
                analyticsIdentify(registerData.id, {
                    Has_Ever_Had_Paying_Account: false,
                    firstName: this.currentAccount.firstName,
                    lastName: this.currentAccount.name,
                    email: this.currentAccount.email,
                    phone: this.currentAccount.company.phone
                });
                analyticsTrack('Sign Up', {
                    method: 'mail'
                });

                this.$gtmTrackEvent({
                    event: 'register-form-submitted',
                    userId: registerData.id,
                    email: emailInput,
                    firstName: registerData.userinformation.firstName,
                    lastName: registerData.userinformation.name
                });

                this.resetForm();

                // Login

                await this.login({ email: emailInput, password: passwordInput });

                resetLoggingState();

                const [userData] = await Promise.all([
                    this.$store.dispatch('auth/getCurrentUser'),
                    this.$store.dispatch('auth/getPermissions')
                ]);

                analyticsIdentify(userData.id, {
                    email: emailInput,
                    firstName: userData.userinformation.firstName,
                    lastName: userData.userinformation.name
                });
                analyticsTrack('Sign In');

                // Fetch current user
                this.$router.push({ name: 'emailadres-verified' });
            } catch (err) {
                apiErrorAndDisplay.call(this, err);
            } finally {
                this.loading = false;
            }
        }
    }
});
